/* Other rules */
/*  .... COLOR MEMO ....
  background-color: #ec1d24;
  background-color: #9f0013;
  background-color: #100d01;
  background-color: #202020;   lighter* 
 */

/* Hide scrollbars */
.comic-desc::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.comic-desc {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  font-family: "Roboto Condensed", sans-serif;
}

.container {
  width: 60vw;
  min-height: 100vh;
  margin: 20px auto;
}

p {
  margin: 0;
}

/* Header and Nav bar */
/* ---------------------------------------------------------------- */
.header {
  background-color: #202020;
  color: white;
  font-weight: bold;
  margin: 0;
  position: sticky;
  z-index: 555;
  top: 0;
}

.pan-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.nav {
  width: 60vw;
  /* height: 52px; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-items {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  width: 220px;
}

.favoris {
  color: #adb5bd;
}

.btn-login {
  margin: 0px 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 26px;
  width: 160px;
  background-color: #ec1d24;
  color: white;
  border: 1px solid white;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
}

/* Sign .in ...  -------------------- */

.sign-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 16px;
}

.login-ins {
  font-size: 12px;
  color: white;
}

.user {
  color: white;
  font-size: 12px;
  outline: #f5f6f7;
}

.username {
  color: white;
}

#link,
#link:visited,
a {
  color: white;
  text-decoration: none;
}

/* Filter .. Search bar--------------------- */
.filter {
  font-size: 12px;
  height: 40px;
  padding: 1px 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #202020;
  border-top: 1px solid #6c757d;
  position: sticky;
  top: 53px;
  z-index: 555;
}

.search-pan {
  flex-grow: 2;
}
.search-pan select {
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  box-sizing: border-box;
}
.filter select:nth-child(n + 2) {
  font-size: 16px;
  font-weight: bold;
}

.filter select option {
  font-size: 16px;
  font-weight: bold;
}

.search-bar {
  font-size: 16px;
  height: 28px;
  width: 70%;
  padding-left: 20px;
  margin-left: 0;
  background-color: #f5f6f7;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border: none;
  box-sizing: border-box;
}

.search-bar:focus {
  outline: none;
}

.filter-icon {
  font-size: 16px;
  color: white;
}

.pages > * {
  margin-left: 10px;
}

.pages select {
  font-size: 16px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Comic Card ................ */
.main-pan {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.card-pan {
  margin: 20px 0px;
}

.comic-card {
  width: 224px;
  height: 470px;
  background-color: #dee2e6;
  border-bottom-right-radius: 20px;
  transition: transform 250ms;
}

.comic-card:hover {
  transform: translateY(-12px);
  background-color: #52b788;
}

.char-card {
  width: 224px;
  height: 470px;
  background-color: #dee2e6;
  border-bottom-right-radius: 20px;
  transition: transform 250ms;
}
.char-card:hover {
  transform: translateY(-12px);
  background-color: #5390d9;
  color: white;
}

.comic-img {
  height: 336px;
  width: 224px;
}

.comic-title {
  font-size: 14px;
  height: 30px;
  padding: 0 5px;
  font-weight: bold;
  margin: 10px 0px;
}

.comic-desc {
  font-size: 12px;
  height: 48px;
  padding: 0 5px;
  overflow-x: hidden;
}

.fav-pan {
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fav-pan:last-child {
  color: #9f0013;
}

/* Footer.......................... */

.footer {
  padding: 5px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ebedee;
}

.foot-notes {
  font-size: 12px;
  line-height: 40px;
  color: #495057;
  text-decoration: none;
}

.foot-notes a {
  text-decoration: none;
}

.form-pan {
  min-height: 100vh;
}

#footer-link,
#footer-link2,
#link3 {
  color: #495057;
}

.fav-mobile {
  font-size: 20px;
  padding: 8px;
}

#fav-mobile {
  color: #ec1d24;
}
/* ___________________________________________________________________________________________________________________________________ */

/* General Typesetting .. Fonts ----------------------------------------------- */

/* Buttons ... misc --------------------------------------------- */

.btn-login:hover,
.burger:hover {
  cursor: pointer;
}

.offers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
}

.card {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 500px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
}

.card > :nth-child(n + 4) {
  margin-left: 8px;
  color: #6c757d;
}

.card > :nth-child(3) {
  margin-left: 8px;
  color: black;
}

.card > :first-child {
  margin: 8px;
  color: black;
}

.card > p {
  margin-bottom: 5px;
}

.card-image {
  margin-bottom: 10px;
  width: 240px;
  height: 380px;
  object-fit: cover;
}

.product-card {
  margin: 0px auto;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
}

.product-info {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  gap: 20px;
}

.product-info > button {
  justify-content: center;
}

.product-info > ul {
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
}

.product-info > :first-child {
  font-size: 16px;
  font-weight: bold;
}

.product-keys {
  display: flex;
  font-size: 13px;
  line-height: 19px;
}

.product-keys :first-child {
  color: #6c757d;
  min-width: 60%;
}

.product-keys span {
  flex: 1;
}

.product-keys-checkout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cart-total {
  font-weight: bold;
}

.product-image {
  height: 500px;
  width: 300px;
  object-fit: cover;
  border-radius: 5px;
}

.product,
.login-pan {
  padding-top: 50px;
  margin: 0px;
  min-width: 100vw;
  min-height: 90vh;
  background-color: #ebedee;
}

.login-pan {
  background-color: white;
}

a {
  text-decoration: none;
}

.login-form {
  margin: 100px auto;
  max-width: 240px;
  margin: 100px auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#offer-form > div > :first-child {
  width: 35%;
  color: #6c757d;
}

.input-txt {
  font-size: 12px;
  margin: 15px auto;
  min-height: 30px;
  width: 100%;
  padding-left: 20px;
  background-color: #f5f6f7;
  border-radius: 5px;
  border: none;
}

.input-txt:focus {
  outline: none;
}

.fav-mobile,
#signin-hide,
.hide,
.burger {
  display: none;
}

.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* RESPONSIVE ... media queries................................................. */

/* First breack point.... */
@media (max-width: 560px) {
  #login-hide,
  #filter-hide,
  .hide {
    display: none;
  }

  .pan-image {
    height: 120px;
  }

  .filter {
    padding: 0px 10%;
    display: flex;
    height: 48px;
    flex-direction: column;
    justify-content: space-evenly;
    top: 105px;
  }

  .search-pan {
    margin-top: 3px;
    height: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
  }
  .search-pan select {
    font-size: 10px;
    height: 20px;
    max-width: 80px;
  }
  .filter select:nth-child(n + 2) {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 3px;
  }

  .filter select option {
    font-size: 10px;
  }

  .search-bar {
    font-size: 12px;
    height: 19px;
    flex-grow: 2;
    /* width: 80%; */
    padding-left: 10px;
  }

  .search-bar:focus {
    outline: none;
  }

  .filter-icon {
    font-size: 16px;
    color: white;
  }

  .pages > * {
    margin-left: 5px;
  }

  .pages select {
    font-size: 16px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
/* Break Point ----------------------------------- */
@media (max-width: 750px) {
  #login-hide,
  #filter-hide,
  .hide {
    display: none;
  }

  .pan-image {
    height: 200px;
  }

  .nav-items {
    display: none;
  }

  .fav-mobile,
  .burger {
    display: flex;
    align-self: center;
  }

  .nav {
    width: 80vw;
    flex-wrap: wrap;
  }

  .nav > div:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .nav > div:last-child {
    width: 100%;
    display: flex;
  }

  .burger {
    font-size: 30px;
    border: 1px solid white;
    padding: 5px;
    border-radius: 4px;
  }

  .search-pan select {
    max-width: 100px;
  }

  /* ----------------------------------------------------- */
  .filter {
    padding: 0px 10%;
    justify-content: space-between;
    top: 91px;
  }

  .search-pan {
    flex-grow: 1;
  }
  .search-pan select {
    font-size: 12px;
  }
  .filter select:nth-child(n + 2) {
    font-size: 12px;
  }

  .filter select option {
    font-size: 12px;
  }

  .search-bar {
    padding-left: 10px;
    font-size: 12px;
    width: 50%;
  }

  /* ----------------------------------------------------- */

  .container {
    width: 80vw;
  }
}

/* Break Point --------------------------------- */
@media (min-width: 751px) and (max-width: 900px) {
  .pan-image {
    height: 300px;
  }

  .container {
    width: 70vw;
  }

  .search-pan select {
    max-width: 100px;
  }

  .fav-mobile {
    display: flex;
  }
  .nav-items,
  .burger {
    display: none;
  }
  .search-bar {
    font-size: 12px;
    width: 50%;
  }

  .filter select option {
    font-size: 12px;
  }

  .filter select option {
    font-size: 12px;
  }
}

@media (min-width: 901px) and (max-width: 1050px) {
  .search-bar {
    font-size: 12px;
    width: 55%;
  }
}
